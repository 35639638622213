var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.isLoading)?_c('trac-loading'):_vm._e(),(_vm.stores && _vm.transfers)?_c('main',{staticClass:"ml-12"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"flex flex-row justify-between items-end"},[_vm._m(0),_c('div',{staticClass:"mb-8"},[_c('trac-button',{staticClass:"uppercase",on:{"button-clicked":function($event){return _vm.$router.push({ name: 'CreateTransfer' })}}},[_vm._v(" Create Transfer ")])],1)])]),_c('div',{staticClass:"bg-white rounded-lg h-full py-2 big-shadow"},[_c('div',{staticClass:"mt-8 mb-5 mx-8 justify-between flex flex-row"},[_c('div',{staticClass:"flex gap-5 items-center w-6/12"},[_c('div',[_c('label',{staticClass:"text-xs"},[_vm._v("Transfer type")]),_c('trac-dropdown-exteneded',{staticClass:"mb-6 capitalize",attrs:{"placement":"left","selector":_vm.typeOptions.find((opt) => opt.value === _vm.params.type) &&
                _vm.typeOptions.find((opt) => opt.value === _vm.params.type).label,"options":_vm.typeOptions,"neededProperty":'label'},on:{"optionSelected":_vm.selectType}})],1),_c('div',[_c('label',{staticClass:"text-xs"},[_vm._v("Store")]),_c('trac-dropdown-exteneded',{staticClass:"mb-6 capitalize",attrs:{"placement":"left","selector":_vm.selectedStore.name,"options":_vm.stores,"neededProperty":'name'},on:{"optionSelected":function($event){return _vm.selectOption($event, 'selectedStore')}}})],1)])]),_c('div',{staticClass:"mx-8 mb-8 border rounded-lg overflow-auto"},[_c('table',{staticClass:"w-full table-auto h-full overflow-auto"},[_vm._m(1),(_vm.transfers.length > 0)?_c('tbody',_vm._l((_vm.transfers),function(transfer,index){return _c('tr',{key:index,staticClass:"hover:bg-gray-200 cursor-pointer",class:index % 2 === 0 ? '' : 'bg-gray-100',on:{"click":function($event){return _vm.gotoTransferDetails(transfer, index)}}},[_c('td',{staticClass:"text-sm font-bold p-4 capitalize"},[_vm._v(" "+_vm._s("Transfer Order " + (_vm.params.page * _vm.params.limit - _vm.params.limit + (index + 1)))+" ")]),_c('td',{staticClass:"text-xs p-4 capitalize"},[_vm._v(" "+_vm._s(transfer.source.store_name)+" ")]),_c('td',{staticClass:"text-xs font-medium p-4 capitalize"},[_vm._v(" "+_vm._s(transfer.destination.store_name)+" ")]),_c('td',{staticClass:"text-xs font-medium p-4 capitalize",class:[
                    'partially_received',
                    'fully_received',
                    'sent_and_recieved',
                    'Transferred',
                  ].includes(transfer.status)
                    ? 'text-secondaryGreen'
                    : transfer.status === 'on_hold'
                    ? 'text-primaryBlue'
                    : transfer.status === 'in_transit'
                    ? 'text-yellow-500'
                    : 'text-secondaryRed'},[_vm._v(" "+_vm._s(transfer.status.replace("_", " "))+" ")]),_c('td',{staticClass:"text-xs font-medium p-4 capitalize"},[_vm._v(" "+_vm._s(transfer.initiated_by.name)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('trac-center-data',[_c('div',{staticClass:"h-40 flex items-center text-lg text-gray-600"},[_vm._v(" No transfers done yet. ")])])],1)])])])]),_c('div',{staticClass:"flex justify-center"},[(_vm.totalRecords > _vm.params.limit)?_c('pagination',{staticClass:"mt-4 mx-auto",attrs:{"value":_vm.params.page,"records":_vm.totalRecords,"per-page":_vm.params.limit},on:{"input":(e) => {
              _vm.params.page = e;
              _vm.fetchAllTransfers();
            }}}):_vm._e()],1)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"flex-col mt-12 mb-8"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Inventory Control")])]),_c('li',{staticClass:"font-bold mt-4 text-2xl"},[_vm._v("Inventory Transfer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-blue-100"},[_c('tr',{},[_c('th',{staticClass:"text-left text-xs font-semibold p-4 uppercase"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left text-xs font-semibold p-4 uppercase"},[_vm._v(" Source Store ")]),_c('th',{staticClass:"text-left text-xs font-semibold p-4 uppercase"},[_vm._v(" Destination Store ")]),_c('th',{staticClass:"text-left text-xs font-semibold p-4 uppercase"},[_vm._v(" Transfer Status ")]),_c('th',{staticClass:"text-left text-xs font-semibold p-4 uppercase"},[_vm._v(" Ordered By ")])])])
}]

export { render, staticRenderFns }