<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="stores && transfers">
      <div class="wrapper">
        <div class="flex flex-row justify-between items-end">
          <ul class="flex-col mt-12 mb-8">
            <li><a href="#">Inventory Control</a></li>
            <li class="font-bold mt-4 text-2xl">Inventory Transfer</li>
          </ul>
          <div class="mb-8">
            <trac-button
              @button-clicked="$router.push({ name: 'CreateTransfer' })"
              class="uppercase"
            >
              Create Transfer
            </trac-button>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-lg h-full py-2 big-shadow">
        <div class="mt-8 mb-5 mx-8 justify-between flex flex-row">
          <div class="flex gap-5 items-center w-6/12">
            <div>
              <label class="text-xs">Transfer type</label>
              <trac-dropdown-exteneded
                @optionSelected="selectType"
                placement="left"
                :selector="
                  typeOptions.find((opt) => opt.value === params.type) &&
                  typeOptions.find((opt) => opt.value === params.type).label
                "
                :options="typeOptions"
                :neededProperty="'label'"
                class="mb-6 capitalize"
              >
              </trac-dropdown-exteneded>
            </div>
            <!-- <div>
              <label class="text-xs">Source store</label>
              <trac-dropdown-exteneded
                @optionSelected="selectOption($event, 'sourceStore')"
                placement="left"
                :selector="sourceStore.name"
                :options="stores"
                :neededProperty="'name'"
                class="mb-6 capitalize"
              >
              </trac-dropdown-exteneded>
            </div> -->
            <div>
              <label class="text-xs">Store</label>
              <trac-dropdown-exteneded
                @optionSelected="selectOption($event, 'selectedStore')"
                placement="left"
                :selector="selectedStore.name"
                :options="stores"
                :neededProperty="'name'"
                class="mb-6 capitalize"
              >
              </trac-dropdown-exteneded>
            </div>
          </div>
        </div>
        <div class="mx-8 mb-8 border rounded-lg overflow-auto">
          <table class="w-full table-auto h-full overflow-auto">
            <thead class="bg-blue-100">
              <tr class="">
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Name
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Source Store
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Destination Store
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Transfer Status
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Ordered By
                </th>
              </tr>
            </thead>
            <tbody v-if="transfers.length > 0">
              <tr
                v-for="(transfer, index) in transfers"
                :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                :key="index"
                class="hover:bg-gray-200 cursor-pointer"
                @click="gotoTransferDetails(transfer, index)"
              >
                <td class="text-sm font-bold p-4 capitalize">
                  {{
                    "Transfer Order " +
                    (params.page * params.limit - params.limit + (index + 1))
                  }}
                </td>
                <td class="text-xs p-4 capitalize">
                  {{ transfer.source.store_name }}
                </td>
                <td class="text-xs font-medium p-4 capitalize">
                  {{ transfer.destination.store_name }}
                </td>
                <td
                  class="text-xs font-medium p-4 capitalize"
                  :class="
                    [
                      'partially_received',
                      'fully_received',
                      'sent_and_recieved',
                      'Transferred',
                    ].includes(transfer.status)
                      ? 'text-secondaryGreen'
                      : transfer.status === 'on_hold'
                      ? 'text-primaryBlue'
                      : transfer.status === 'in_transit'
                      ? 'text-yellow-500'
                      : 'text-secondaryRed'
                  "
                >
                  {{ transfer.status.replace("_", " ") }}
                </td>
                <td class="text-xs font-medium p-4 capitalize">
                  {{ transfer.initiated_by.name }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      No transfers done yet.
                    </div>
                  </trac-center-data>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex justify-center">
          <pagination
            v-if="totalRecords > params.limit"
            class="mt-4 mx-auto"
            :value="params.page"
            :records="totalRecords"
            :per-page="params.limit"
            @input="
              (e) => {
                params.page = e;
                fetchAllTransfers();
              }
            "
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";
import Pagination from "vue-pagination-2";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isLoading: false,
      stores: null,
      sourceStore: {},
      selectedStore: {},
      transfers: null,
      totalRecords: 0,
      type: "outbound",
      params: {
        limit: 25,
        page: 1,
        type: "both",
      },
      typeOptions: [
        { label: "All", value: "both" },
        { label: "Incoming", value: "inbound" },
        { label: "Outgoing", value: "outbound" },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    DELETE_LOCAL_DB_DATA("transfer-details");

    await this.fetchAllStores();
    await this.fetchAllTransfers();
    this.isLoading = false;
  },
  methods: {
    gotoTransferDetails(transfer, index) {
      SAVE_LOCAL_DB_DATA("transfer-details", {
        ...transfer,
        name:
          "Transfer Order " +
          (this.params.page * this.params.limit -
            this.params.limit +
            (index + 1)),
      });
      this.$router.push({ name: "TransferDetails" });
    },
    async selectOption(store, where) {
      this.isLoading = true;
      this[where] = store;
      this.params.page = 1;
      await this.fetchAllTransfers();
      this.isLoading = false;
    },
    async selectType({ value }) {
      this.isLoading = true;
      this.params.type = value;
      this.params.page = 1;
      await this.fetchAllTransfers();
      this.isLoading = false;
    },
    async fetchAllTransfers() {
      const res = await this.$store.dispatch("FETCH_ALL_TRANSFERS_V2", {
        ...this.params,
        store: this.selectedStore?._id || GET_USER_BUSINESS_DATA()._id,
      });

      if (res.status) {
        this.transfers = res.data?.inventory_transfers || [];
        this.totalRecords = res.data.total_count;
        // this.transfers = this.transfers.reverse();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
        // this.sourceStore = this.stores[0];
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
  },
};
</script>

<style></style>
